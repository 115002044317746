<template>
  <div class="banner">
    <img src="../../assets/images/banner.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.banner {
    height: 390px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
  }
</style>