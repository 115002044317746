<template>
  <div class="complateUser">
    <top />
    <div class="btn">
      <el-button class="operation-btn">信息完善</el-button>
    </div>
    <div class="inner-container">
      <div class="input-container">
        <input type="text"
               v-model="formData.username"
               placeholder="请输入用户名"
               :disabled="userInfo.userName?true:false">
      </div>

      <div class="input-container">
        <input type="text"
               v-model="formData.realName"
               placeholder="请输入真实姓名">
      </div>

      <div class="input-container">
        <input type="text"
               v-model="formData.phonenumber"
               placeholder="请输入手机号">
      </div>

      <div class="btn-wrapper">
        <div class="button"
             @click="completeUser">提交</div>
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex';
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
export default {
  components: {
    top,
    bottom,
  },
  data () {
    return {
      formData: {
        username: "",
        realName: "",
        phonenumber: "",
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'loginPopupShow'])
  },

  created () {
    this.formData.username = this.userInfo.userName
    this.formData.realName = this.userInfo.realName
    this.formData.phonenumber = this.userInfo.phonenumber
  },
  methods: {
    completeUser (userName = "") {
      if (!this.formData.username) {
        this.$message.error('请输入用户名');
        return;
      }

      if (!this.formData.realName) {
        this.$message.error('请输入真实姓名');
        return;
      }

      if (!this.formData.phonenumber) {
        this.$message.error('请输入手机号');
        return;
      }
      this.$myRequest
        .post("hsgov/completeUser", {
          ...this.formData
        }).then(res => {
          this.getInfo()
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.$router.go(-1)

          // uni.navigateBack({

          // })
        })
    },

    getInfo () {
      this.$myRequest
        .get('hsgov/getInfo', {})
        .then(res => {

        });

    }
  }

}
</script>

<style lang="scss" scoped>
.complateUser {
  background: #f5f5f5;
  .btn {
    width: 900px;
    height: 56px;
    margin: 0 auto;
    margin-top: 69px;

    .operation-btn {
      width: 100%;
      height: 100%;
      line-height: 100%;
      border: 0;
      background: #0366e2;
      font-size: 18px;
      color: #ffffff;
      border-radius: 8px 8px 0 0;
    }
  }

  .inner-container {
    background: #ffffff;
    width: 900px;
    height: 420px;
    margin: 0 auto;
    padding: 73px 51px 66px 51px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-container {
      width: 467px;
      height: 38px;
      margin-bottom: 15px;

      input {
        width: 100%;
        height: 100%;
        padding-left: 17px;
        border-radius: 4px;
        border: 1px solid #d9dde1;
      }
    }

    .btn-wrapper {
      margin-top: 50px;
      .button {
        width: 90px;
        height: 35px;
        background: #0366e2;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-input,
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border: 0;
}
</style>