<template>
  <div class="container">
    <top />
    <banner />
    <div class="list-title-container">
      <div class="list-title">
        <span @click="$router.push('/')">首页&nbsp;>&nbsp;</span>
        <span>{{title}}</span>
        <div class="search-box">
          <el-input v-model="searchKey"
                    placeholder="请输入搜索内容"></el-input>
          <img src="../assets/images/search-icon.png"
               alt=""
               @click="search">
        </div>
      </div>
    </div>
    <ul class="list"
        v-loading="loading">
      <li class="list-item"
          v-for="(item,index) in listData"
          :key="index"
          @click="getDetail(item,'REVIEW')">
        <div class="list-item-left">
          <div class="date-top">{{item.createTime.slice(8,10)}}</div>
          <div class="date-bottom">
            {{item.createTime.slice(0,7)}}
          </div>
        </div>
        <div class="list-item-right">
          <p class="main-tit">
            <el-tooltip class="item"
                        v-if="item.title.length>32"
                        effect="dark"
                        :content="item.title"
                        placement="top-start">
              <span v-if="item.title.length>32">{{item.title}}</span>
            </el-tooltip>
            <span v-else>{{item.title}}</span>

            <span v-if="suffixUrl==='zwbs'&&item.rpSend==='Y'">(已采用)</span>
            <span v-if="suffixUrl==='zwbs'&&item.rpSend==='N'">(未采用)</span>
            <span v-if="suffixUrl==='zwbs'&&item.rpSend==='T'">(已退回)</span>
            <!-- <span>{{item.createBy}}</span> -->
          </p>
          <p class="sub-tit"
             v-if="suffixUrl==='search'">{{item.bussesType | businessTypeFilter}}</p>
          <p class="sub-tit"
             v-else>{{title}}</p>

          <p class="createBy"><span>{{item.createBy}}</span></p>
        </div>

        <div class="icon-list">
          <el-tooltip class="item"
                      effect="dark"
                      content="预览"
                      placement="top-start">
            <img src="../assets/images/preview.png"
                 class="previewIcon"
                 @click.stop="preview(item,'DOWLOAD')"
                 alt="">
          </el-tooltip>
          <el-tooltip class="item"
                      effect="dark"
                      content="评论"
                      placement="top-start"
                      v-if="suffixUrl!=='scj'">
            <img src="../assets/images/evalute.png"
                 @click.stop="goToEvalute(item)"
                 v-if="suffixUrl!=='scj'"
                 alt="">
          </el-tooltip>

          <el-tooltip class="item"
                      effect="dark"
                      content="收藏"
                      placement="top-start"
                      v-if="item.isColl==='N'&&suffixUrl!=='zwbs'">
            <img src="../assets/images/sc1.png"
                 @click.stop="collect(item)"
                 v-if="item.isColl==='N'&&suffixUrl!=='zwbs'"
                 alt="">
          </el-tooltip>

          <el-tooltip class="item"
                      effect="dark"
                      content="取消收藏"
                      placement="top-start"
                      v-if="(item.isColl==='Y'&&suffixUrl!=='zwbs')||(suffixUrl==='scj')">
            <img src="../assets/images/sc2.png"
                 @click.stop="unCollect(item)"
                 v-if="(item.isColl==='Y'&&suffixUrl!=='zwbs')||(suffixUrl==='scj')"
                 alt="">
          </el-tooltip>

          <el-tooltip class="item"
                      effect="dark"
                      content="下载"
                      placement="top-start">
            <img src="../assets/images/download.png"
                 @click.stop="getDetail(item,'DOWLOAD')"
                 alt="">
          </el-tooltip>

        </div>

      </li>

      <li v-if="!listData.length"
          style="text-align:center;font-size:16px;margin-bottom:30px">暂无数据</li>

      <div class="add"
           v-if="suffixUrl==='zwbs'"
           @click="handleAdd">
        <img src="../assets/images/add.png"
             v-if="suffixUrl==='zwbs'"
             alt="">
        <span>新增</span>
      </div>
    </ul>

    <!-- <el-tooltip class="item"
                effect="dark"
                content="新增"
                placement="top-start"
                v-if="suffixUrl==='zwbs'">
      
    </el-tooltip> -->

    <div class="pagination-container"
         v-if="listData.length">
      <el-pagination prev-text="上一页"
                     next-text="下一页"
                     layout="prev, pager, next"
                     background
                     :total="total"
                     @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <bottom />

    <comDialog :dialog-config="dialogConfig"
               :form-config="dialogFormConfig"
               :value="dialogFormData"
               @handleSubmitEdit="handleSubmitEdit"
               @handleSubmitAdd="handleSubmitAdd"
               @func="getMsgFormSon" />
    <el-dialog class="yldialog"
               title="预览"
               :visible.sync="yulanVisible"
               :fullscreen="true"
               :before-close="yulanClose">
      <img :src="imgUrl"
           style="object-fit: contain;width:800px;margin:0 auto;"
           alt=""
           v-if="imgUrl">
      <div v-if="pdfUrl">
        <pdf ref="pdf"
             v-for="i in numPages"
             :key="i"
             :src="pdfUrl"
             :page="i"></pdf>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import comDialog from '@/components/general/dialog'
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
import banner from "@/components/general/banner.vue";

export default {
  components: {
    top,
    bottom,
    banner,
    comDialog,
    pdf
  },
  data () {
    return {
      loading: false,
      total: 0,
      dialogConfig: {
        show: false,
      },
      dialogFormData: {
      },
      dialogFormConfig: {},
      searchKey: "",
      suffixUrl: "",
      title: "",
      loadDataUrl: "",
      listData: [],
      queryParams: {
        plateType: ""
      },
      placeholder: "",
      currentPage: 1,
      pageSize: 10,
      config: {
        "zwbs": "RP",
        "zwcy": "GOV",
        "qtwj": "GOV",
        "scj": "GOV",
        "xxtz": "GOV",
        "xxgx": "SD",

      },
      params: "",
      previewUrl: "",
      yulanVisible: false,
      imgUrl: "",
      pdfUrl: "",
      numPages: 0, // pdf 总页数
    }
  },
  created () {
    // 报送-RP、政务-GOV、共享-SD、活动-AT

    this.suffixUrl = this.$route.query.suffixUrl
    this.params = this.$route.query.params


    if (this.suffixUrl === 'zwbs') {
      this.title = "信息报送"
      this.placeholder = "搜索信息报送信息"
      this.loadDataUrl = "hsgov/gov/rp/queryRpList"
      this.queryParams.plateType = ""
    } else if (this.suffixUrl === 'zwcy') {
      this.title = "政务参阅"
      this.placeholder = "搜索政务参阅信息"
      this.loadDataUrl = "hsgov/gov/gov/queryList"
      this.queryParams.plateType = "ZWCY"
    } else if (this.suffixUrl === 'qtwj') {
      this.title = "其他文件"
      this.placeholder = "搜索其他文件信息"
      this.loadDataUrl = "hsgov/gov/gov/queryList"
      this.queryParams.plateType = "QTWJ"
    } else if (this.suffixUrl === 'xxtz') {
      this.title = "信息通知"
      this.placeholder = "搜索信息通知信息"
      // this.loadDataUrl = "hsgov/gov/act/queryActList"
      // this.queryParams.plateType = ""
      this.loadDataUrl = "hsgov/gov/gov/queryList"
      this.queryParams.plateType = "XXTZ"
      // this.getList('gov/act/queryActList')
    } else if (this.suffixUrl === 'xxgx') {
      this.title = "信息共享"
      this.placeholder = "搜索信息共享信息"
      this.loadDataUrl = "hsgov/gov/shared/querySharedList"
      this.queryParams.plateType = ""

      // this.getList('gov/shared/querySharedList')
    } else if (this.suffixUrl === 'scj') {
      this.title = "收藏夹"
      this.placeholder = "搜索收藏夹信息"
      this.loadDataUrl = "hsgov/coll/queryList"
      this.queryParams.plateType = ""
      // this.getList('coll/queryList')
    } else if (this.suffixUrl === 'search') {
      this.title = "搜索列表"
      this.placeholder = "请输入搜索内容"
      this.loadDataUrl = "hsgov/gov/gov/queryGlobalList"
      this.queryParams.plateType = ""
    } else {

    }

    this.loadData()
  },
  filters: {
    businessTypeFilter (val) {
      const config = {
        "RP": "信息报送",
        "GOV": "政务信息",
        "AT": "信息通知",
        "SD": "信息共享",
      }

      return config[val]
    }
  },
  methods: {
    //计算pdf页码总数
    getNumPages (url) {
      let loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
        this.pdfUrl = url
      }).catch(err => {
        console.error('pdf 加载失败', err);
      })
    },
    yulanClose () {
      this.yulanVisible = false;
    },
    getMsgFormSon (data) {
      this.dialogFormData = {
        title: data
      }
    },
    handleCurrentChange (pageNum) {
      this.currentPage = pageNum
      this.loadData()
    },
    search () {
      this.currentPage = 1
      this.loadData()
    },
    goToEvalute (item) {
      this.$router.push({
        path: "/evaluteList",
        query: { id: item.id }
      })
    },
    handleAdd () {
      this.dialogConfig = {
        show: true,
        option: 'add'
      }

      this.dialogFormData = {}
    },
    async loadData () {
      this.loading = true;
      const res = await this.$myRequest.post(this.loadDataUrl, {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        ...this.queryParams,
        title: this.searchKey || this.params
      });

      this.loading = false

      this.listData = res.data.list
      this.total = res.data.totalRow
    },
    // 收藏
    collect (item) {
      let id, bussesType;
      if (this.suffixUrl === 'scj') {
        id = item.relationId
        bussesType = item.businessType
      } else {
        id = item.id
        bussesType = this.config[this.suffixUrl]
      }
      // 报送-RP、政务-GOV、共享-SD、活动-AT
      this.operate(id, "COLL", item)
      this.$myRequest
        .post("hsgov/coll/star", {
          businessType: bussesType,
          relationId: id,
        }).then(res => {
          if (res.data) {
            this.$message({
              message: '收藏成功',
              type: 'success'
            });
            this.loadData()
          } else {
            this.$message.error('收藏失败');
          }
        })
    },

    // 取消收藏
    unCollect (item) {
      let id;
      if (this.suffixUrl === 'scj') {
        id = item.relationId
      } else {
        id = item.id
      }
      this.$myRequest
        .delete("hsgov/coll/cancel?id=" + id).then(res => {
          if (res.data) {
            this.$message({
              message: '取消收藏成功',
              type: 'success'
            });
            this.loadData()
          } else {
            this.$message.error('取消收藏失败');
          }
        })
    },

    // /operate/record

    operate (relationId, operateMsg, item) {
      let bussesType;
      if (this.suffixUrl === 'scj') {
        bussesType = item.businessType
      } else {
        bussesType = this.config[this.suffixUrl]
      }
      this.$myRequest
        .post("hsgov/operate/record", {
          businessType: bussesType,
          relationId,
          operateMsg,
        }).then(res => {

        })
    },
    getDetail (item, type) {
      let id;
      if (this.suffixUrl === 'scj') {
        id = item.relationId
      } else {
        id = item.id
      }
      this.operate(id, type, item)
      let fileName = item.fileList[0].fileLocation
      this.$myRequest
        .get("hsgov/common/getSecurityUrl?fileName=" + fileName).then(res => {
          // this.downUrl = res.data
          window.location.href = res.data
        })
    },

    preview (item, type) {
      let id;
      if (this.suffixUrl === 'scj') {
        id = item.relationId
      } else {
        id = item.id
      }
      this.operate(id, type, item)
      let fileName = item.fileList[0].fileLocation

      if (!item.fileList[0].fileName) {
        this.$message.error('没有文件可以预览');
        return
      }

      this.imgUrl = ""
      this.pdfUrl = ""
      // if (/.(pdf|PDF|et|ET|wps|WPS)$/.test(item.fileList[0].fileName)) {
      //   this.$myRequest
      //     .get("hsgov/common/getSecurityUrl?fileName=" + fileName).then(res => {
      //       window.open(res.data, '_blank');
      //     })
      // } else {
      //   this.$myRequest
      //     .get("/hsgov/common/getAuthSecurity?fileName=" + fileName).then(response => {
      //       if (!response.data) {
      //         this.$message.error('获取安全链接异常');
      //         return;
      //       }
      //       const securityCode = response.data;
      //       const dowloadUrl = "http://www.hengshanzw.com/hsmg/common/downloadPreview/" + securityCode + "?fileName=" + fileName;
      //       const micUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + dowloadUrl;
      //       window.open(micUrl, '_blank');
      //     })
      // }


      if (/.(GIF|JPG|PNG)$/.test(fileName.toUpperCase())) {
        this.$myRequest
          .get("hsgov/common/getSecurityUrl?fileName=" + fileName).then(res => {
            this.yulanVisible = true;
            this.imgUrl = res.data
          })
      } else if (/.(PDF)$/.test(fileName.toUpperCase())) {
        this.$myRequest
          .get("hsgov/common/getSecurityUrl?fileName=" + fileName).then(res => {
            this.yulanVisible = true;
            this.getNumPages(res.data)
          })
      } else if (/.(ET|WPS|TEXT)$/.test(fileName.toUpperCase())) {
        this.$myRequest
          .get("hsgov/common/getSecurityUrl?fileName=" + fileName).then(res => {
            window.open(res.data, '_blank');
          })
      } else {
        this.$myRequest
          .get("/hsgov/common/getAuthSecurity?fileName=" + fileName).then(response => {
            if (!response.data) {
              this.$message.error('获取安全链接异常');
              return;
            }
            const securityCode = response.data;
            const dowloadUrl = "http://www.hengshanzw.com/hsmg/common/downloadPreview/" + securityCode + "?fileName=" + fileName;
            const micUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + dowloadUrl;
            window.open(micUrl, '_blank');
          })
      }
    },
    search () {
      this.loadData();
    },

    handleSubmitAdd (data) {
      let fileList = [{
        name: "", location: ""
      }]

      fileList[0].name = data.name
      fileList[0].location = data.fileName
      this.$myRequest
        .post("hsgov/gov/rp/saveRp", {
          title: data.title,
          fileList: fileList
        }).then(res => {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.dialogConfig = {
            show: false,
          }
          this.loadData()
        })
    },
    handleSubmitEdit () {

    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .list {
    position: relative;
  }
  .list-title-container {
    background: #f5f5f5;
  }
  .list-title {
    height: 45px;

    // line-height: 45px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;

    width: 900px;
    margin: 0 auto;
    position: relative;
    span {
      display: inline-block;
      line-height: 45px;
      cursor: pointer;
    }

    span {
      &:first-child {
        color: #999999;
      }

      &:last-child {
        color: #000000;
      }
    }
  }

  .search-box {
    position: absolute;
    right: 0;
    top: 8px;
    width: 400px;
    height: 30px;
    font-size: 18px;

    img {
      position: absolute;
      right: 14px;
      top: 8px;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  .add {
    position: absolute;
    top: 0;
    right: 30px;
    cursor: pointer;
    cursor: pointer;
    width: 140px;

    img {
      width: 126px;
      height: 126px;
    }

    span {
      font-size: 20px;
      position: absolute;
      right: 0px;
      top: 40px;
      font-weight: bold;
    }
  }

  .pagination-container {
    text-align: center;
    margin: 55px 0 106px 0;

    ::v-deep .btn-prev,
    ::v-deep .btn-next {
      border: 1px solid #cccccc;
      font-size: 9px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #aaaaaa;
      background: #fff !important;
      padding: 0 10px;
    }

    ::v-deep .el-pager {
      li {
        border: 1px solid #cccccc;
        font-size: 9px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #aaaaaa;
        background: #fff !important;
        padding: 0 10px;

        &:hover {
          border: 1px solid #0366e2;
          background: #0366e2 !important;
          color: #fff !important;
        }
      }

      .active {
        border: 1px solid #0366e2;
        background: #0366e2 !important;
        color: #fff;
      }
    }
  }
}

.list {
  padding-top: 45px;
  .list-item {
    width: 900px;
    margin: 0 auto;
    list-style: none;
    margin-bottom: 30px;
    clear: both;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
    height: 94px;
    position: relative;
    display: flex;
    cursor: pointer;

    .createBy {
      position: absolute;
      bottom: 10px;
      right: 0;
      color: #bbbbbb;
      font-size: 16px;
      text-align: right;
      margin-top: 0 !important;
      line-height: 32px !important;
    }

    .icon-list {
      position: absolute;
      right: 0;
      bottom: 40px;
      img {
        width: 14px;
        height: 14px;
        margin-left: 20px;
        cursor: pointer;
      }

      .previewIcon {
        width: 16px;
        height: 16px;
      }
    }
    .list-item-left {
      width: 64px;
      height: 64px;
      background: #fff;
      // float: left;
      margin-right: 15px;
      flex-shrink: 0;

      .date-top {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
        text-align: center;
        background: #f2f2f2;
        margin-bottom: 2px;
      }

      .date-bottom {
        height: 30px;
        line-height: 30px;
        background: #f2f2f2;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bbbbbb;
        font-size: 14px;
        text-align: center;
      }
    }

    .list-item-right {
      flex: 1;
      height: 35px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #010101;

      p {
        // width: 630px;
      }

      p:first-child {
        padding-top: 2px;
        font-size: 18px;
      }

      // p:last-child {
      //   color: #666666;
      //   margin-top: 10px;
      //   font-size: 16px;
      // }

      .sub-tit {
        line-height: 24px !important;
      }

      .main-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:first-child {
          width: 680px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

::v-deep .el-input,
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border-radius: 0;
  border: 0;
  top: 0;
}
</style>