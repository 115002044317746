<template>
  <div id="app">
    <!-- <bartop /> -->
    <router-view />
    <!-- <bottom v-if="!$store.state.screenWidth" />
    <video id="video" width="0" height="0" autoplay></video>
    <canvas
      style="width:0px;height:0px"
      id="canvas"
      width="480"
      height="640"
    ></canvas>
    <input type="hidden" name="img" id="result" value="" /> -->
  </div>
</template>

<script>
import bartop from "@/components/general/bartop.vue";
import bottom from "@/components/general/buttom.vue";
import axios from "axios";
export default {
  components: {
    bartop,
    bottom
  },
  data() {
    return {
      urls: "",
      screenWidth: document.body.clientWidth
    };
  },
  watch: {
    screenWidth(newVal) {}
  },

  
  methods: {
    dataURItoBlob(base64Data) {
      var byteString;
      if (base64Data.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(base64Data.split(",")[1]);
      else byteString = unescape(base64Data.split(",")[1]);
      var mimeString = base64Data
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    }
  }
};
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li{
  list-style: none;
}
html,body,#app{
  width: 100%;
  height: 100%;
}
</style>
