<template>
  <div class="top">
    <div class="top-container">
      <div class="logo"
           @click="goToHome">
        <img src="../../assets/images/logo.png"
             alt="">
        <h3>衡山政务信息管理平台</h3>
      </div>
      <ul>
        <li @click="goToHome">首页</li>
        <!-- <li>数字社会</li>
        <li>数字政府</li>
        <li>数字经济</li> -->
      </ul>
      <div class="logoOut btns">
        <div @click="logoOut">退出登录</div>
      </div>
      <div class="btns">
        <!-- <div>登录</div> -->
        <div @click="goToAdmin">管理台</div>
      </div>
      <div class="userInfo"
           @click="goToUser" v-if="$route.path!=='forget'">
        <!-- <span>当前用户：{{userInfo.nickName}}</span> -->

        <el-tooltip class="item"
                      effect="dark"
                      :content="userInfo.nickName"
                      placement="top-start">
           <span>当前用户：{{userInfo.nickName}}</span>
          </el-tooltip>
      </div>

    </div>
  </div>
</template>

<script>
import {
  removeToken
} from '@/utils/auth'
import {
  mapState,
  mapMutations
} from 'vuex';
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  created () {
    if (this.$route.path !== "/forget") {
      this.getInfo()
    }

  },
  methods: {
    ...mapMutations(['setUserInfo']),
    async getInfo () {
      const res = await this.$myRequest.get("hsgov/getInfo");
      if (res.data.roles[0] !== 'admin') {
        if (!res.data.user.phonenumber) {
          this.$router.push({
            path: "/complateUser"
          })
        }
      }

      this.setUserInfo({
        ...res.data.user
      });
    },
    goToHome () {
      this.$router.push("/")
    },
    goToUser () {
      this.$router.push({
        path: "/userInfo"
      })
    },
    goToAdmin () {
      window.open("http://www.hengshanzw.com/hs-back", "blank");
    },
    // 退出登录
    logoOut () {
      removeToken()
      this.$router.push("/login")
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  height: 53px;
  line-height: 53px;
  background: #fff;

  .top-container {
    width: 900px;
    margin: 0 auto;

    .logo {
      float: left;
      cursor: pointer;

      img {
        width: 38px;
        height: 38px;
        float: left;
        margin-top: 8px;
      }

      h3 {
        font-size: 24px;
        font-family: FZCuHeiSongS-B-GB;
        font-weight: 400;
        color: #0366e2;
        float: left;
        margin: 0 65px 0 10px;
      }
    }

    ul {
      float: left;
      li {
        float: left;
        list-style: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        width: 48px;
        margin-right: 40px;
        cursor: pointer;
      }
    }

    .userInfo {
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      margin-right: 20px;
      cursor: pointer;

      span{
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px;
      }
    }

    .logoOut {
      float: right;
      margin-left: 20px;
    }

    .btns {
      float: right;
      padding-top: 11px;
      cursor: pointer;

      div {
        float: left;
        cursor: pointer;

        &:first-child {
          width: 68px;
          height: 30px;
          border: 1px solid #c7ced7;
          line-height: 30px;
          text-align: center;
          border-radius: 30px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0366e2;
          margin-right: 8px;
        }

        &:last-child {
          width: 100px;
          height: 30px;
          border-radius: 30px;
          background: linear-gradient(90deg, #0366e2, #06adf3);
          line-height: 30px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
</style>