<template>
  <div>
    <div>
      <el-dialog title="新增政务信息"
                 v-loading="loading"
                 :visible.sync="dialogConfig.show"
                 width="50%"
                 destroy-on-close
                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
                 :modal-append-to-body="false"
                 @open="openDialog">
        <el-form ref="ruleForm"
                 :model="value"
                 :rules="rules"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="标题:"
                        prop="title">
            <el-input v-model="value.title"
                      placeholder="请输入标题" />
          </el-form-item>

          <el-form-item label="附件:"
                        prop="src_url">
            <!-- <span>{{ value.src_url }}</span> -->
            <!-- <imageUpload v-model="value.src_url" /> -->
            <!-- <imageUpload v-model="testImg" /> -->

            <el-upload class="upload-demo"
                       :action="action"
                       :on-preview="handlePreview"
                       :on-remove="handleRemove"
                       :before-remove="beforeRemove"
                       :headers="headers"
                       :on-change="handleChange"
                       multiple
                       :limit="2"
                       :on-exceed="handleExceed"
                       :file-list="fileList"
                       :http-request="uploadSectionFile"
                       ref="upload">
              <el-button size="small"
                         type="primary">点击上传</el-button>
              <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
            <el-button size="small"
                       type="primary"
                       style="margin-top:20px"
                       @click="preview">预览</el-button>

          </el-form-item>

        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="dialogConfig.show = false">取 消</el-button>
          <el-button v-if="dialogConfig.option == 'add'"
                     type="primary"
                     :loading="loading"
                     @click="handleAdd('ruleForm')">确 定</el-button>
          <el-button v-if="dialogConfig.option == 'edit'"
                     type="primary"
                     @click="handleEdit('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog class="yldialog"
                 title="预览"
                 :visible.sync="yulanVisible"
                 :fullscreen="true"
                 :before-close="yulanClose">
        <img :src="imgUrl"
             style="object-fit: contain;width:800px;margin:0 auto;"
             alt=""
             v-if="imgUrl">
        <div v-if="pdfUrl">
          <pdf ref="pdf"
               v-for="i in numPages"
               :key="i"
               :src="pdfUrl"
               :page="i"></pdf>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import pdf from 'vue-pdf'

// import ImageUpload from '@/components/general/ImageUpload'
export default {
  components: {
    pdf
    // ImageUpload
  },
  props: {
    formConfig: {
      type: Object,
      default: () => { }
    },
    dialogConfig: {
      type: Object,
      default: () => { }
    },
    formData: {
      type: Object,
      default: () => { }
    },
    value: {
      type: Object,
      default: () => { }
    }
  },
  // created () {
  //   // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
  //   this.pdfUrl = pdf.createLoadingTask(this.pdfUrl)
  // },
  data () {
    return {
      action: process.env.VUE_APP_BASE_API + "hsgov/common/upload",
      loading: false,
      fileObj: {},
      headers: {
        Authorization: getToken()
      },
      fileList: [],
      ruleForm: {
        title: '',
        cover_image: '',
        show_status: true,
        sort: ''
      },

      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ]
        // sort: [
        //   { required: true, message: '输入0-999的数字', trigger: 'blur' }
        // ]
      },
      testImg: {
        // name: 'tom',
        // url: 'www.baidu.com'
      },
      yulanVisible: false,
      Z_yl_iframe: "",
      imgUrl: "",
      pdfUrl: "",
      numPages: 0, // pdf 总页数
    }
  },
  methods: {
    //计算pdf页码总数
    getNumPages (url) {
      let loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
        this.pdfUrl = url
      }).catch(err => {
        console.error('pdf 加载失败', err);
      })
    },
    preview () {
      this.imgUrl = ""
      this.pdfUrl = ""

      if (!this.fileObj.fileName) {
        this.$message.error('没有文件可以预览');
        return
      }
      if (/.(GIF|JPG|PNG)$/.test(this.fileObj.fileName.toUpperCase())) {
        this.$myRequest
          .get("hsgov/common/getSecurityUrl?fileName=" + this.fileObj.fileName).then(res => {
            this.yulanVisible = true;
            this.imgUrl = res.data
          })
      } else if (/.(PDF)$/.test(this.fileObj.fileName.toUpperCase())) {
        this.$myRequest
          .get("hsgov/common/getSecurityUrl?fileName=" + this.fileObj.fileName).then(res => {
            this.yulanVisible = true;
            this.getNumPages(res.data)
          })
      } else if (/.(ET|WPS|TEXT)$/.test(this.fileObj.fileName.toUpperCase())) {
        this.$myRequest
          .get("hsgov/common/getSecurityUrl?fileName=" + this.fileObj.fileName).then(res => {
            window.open(res.data, '_blank');
          })
      } else {
        this.$myRequest
          .get("/hsgov/common/getAuthSecurity?fileName=" + this.fileObj.fileName).then(response => {
            if (!response.data) {
              this.$message.error('获取安全链接异常');
              return;
            }
            const securityCode = response.data;
            const dowloadUrl = "http://www.hengshanzw.com/hsmg/common/downloadPreview/" + securityCode + "?fileName=" + this.fileObj.fileName;
            const micUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + dowloadUrl;
            window.open(micUrl, '_blank');
          })
      }
    },
    yulanClose () {
      this.yulanVisible = false;
    },
    uploadSectionFile (params) {
      this.loading = true
      var file = params.file
      var formData = new FormData();
      formData.append("file", file);
      this.$myRequest
        .post("/hsgov/common/upload", formData).then(res => {
          this.fileObj.fileName = res.data.fileName
          this.fileObj.url = res.data.url
          this.fileObj.name = params.file.name
          // this.value.title = params.file.name.substring(0, params.file.name.lastIndexOf("."))
          this.$emit('func', params.file.name.substring(0, params.file.name.lastIndexOf(".")))
          this.loading = false
          // this.getDialogMsg
        })

    },
    uploadSuccess: function (response, file, fileList) {
      // this.form.file = response.data.webUrlList
      // console.log(this.form)
    },
    handleChange (file, fileList) {
      if (file.size > 1024 * 50000) {
        fileList.pop()
        this.$message({
          message: "耽搁文件不超过50000K",
          type: "error"
        })
      }

      if (file.status === "ready") {
        if (fileList.length === 2) {
          fileList.shift()
        }
      }

      // this.$refs["upload"].submit()
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    openDialog () {
      // this.$nextTick(() => {
      //   this.$refs.form.clearValidate() // 解决dialog打开自动触发了下拉选择框的校验
      // })
    },
    handleAdd (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.fileObj.fileName) {
            this.$message.error("请上传文件")
            return
          }
          let data = {
            ...this.value,
            ...this.fileObj
          }
          this.$emit('handleSubmitAdd', data)

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleEdit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit('handleSubmitEdit', this.ruleForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-input {
  width: 447px !important;
  // height: 35px!important;
  border: 1px solid #dddddd !important;
  border-radius: 4px;
}
</style>
