<template>
  <div class="main">
    <span>豫ICP备20013233号-1</span>
    <!-- <span>© 2015-2021 衡山政务信息管理平台 豫ICP备130322271号</span> -->
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.main {
  position: relative;
  width: 100%;
  height: 15px;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 6px;
    color: #fff;
  }
}
@media screen and (max-width: 850px) {
  .main {
    height: 70px;
  }
}
</style>
