<template>
  <div class="reset">
    <top />
    <div class="btn">
      <el-button class="operation-btn">重置密码</el-button>
    </div>
    <div class="inner-container">
      <div class="input-container">
        <input type="text"
               v-model="username"
               placeholder="请输入手机号">
      </div>

      <div class="input-container code-container">
        <input type="number"
               v-model="code"
               placeholder="请输入验证码">
        <div class="code"
             @click="getCode">{{codeText}}</div>
      </div>

      <div class="input-container">
        <input type="text"
               v-model="password"
               placeholder="请输入密码">
      </div>

      <div class="input-container">
        <input type="text"
               v-model="confirmPassword"
               placeholder="请再次输入密码">
      </div>

      <div class="btn-wrapper">
        <div class="button"
             @click="onSubmit">提交</div>
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
var clear
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
export default {
  components: {
    top,
    bottom,
  },
  data () {
    return {
      codeText: '获取验证码',
      username: "",
      password: "",
      uuid: "",
      code: "",
      //验证码已发
      readonly: false,
      //确认密码
      confirmPassword: '',
    }
  },
  methods: {
    //获取验证码
    getCode () {
      if (this.readonly) {
        this.$message('验证码已发送');
        // this.$message.error('请输入密码');
        return;
      }
      if (!this.username) {
        this.$message.error('请输入手机号');
        return;
      }
      // if (!this.$base.phoneRegular.test(this.username)) {
      //     this.$notify.error({
      //     title: '错误',
      //     message: '请输入正确的手机号'
      //   });
      //   return;
      // }
      this.$myRequest
        .post('hsgov/captchaPhone', {
          phone: this.username
        })
        .then(res => {
          this.$message({
            message: '验证码发送成功',
            type: 'success'
          });
          this.uuid = res.data.uuid
          this.getCodeState();
        });
    },


    //验证码按钮文字状态
    getCodeState () {
      const _this = this;
      this.readonly = true;
      this.codeText = '60S后重新获取';
      var s = 60;
      clear = setInterval(() => {
        s--;
        _this.codeText = s + 'S后重新获取';
        if (s <= 0) {
          clearInterval(clear);
          _this.codeText = '获取验证码';
          _this.readonly = false;
        }
      }, 1000);
    },
    onSubmit () {
      if (!this.username) {
        this.$message.error('请输入手机号');
        return;
      }
      // if (!this.$base.phoneRegular.test(this.username)) {
      //   uni.showToast({
      //     title: '请输入正确的手机号',
      //     icon: 'none'
      //   });
      //   return;
      // }
      if (!this.code) {
        this.$message.error('请输入验证码');
        return;
      }
      if (!this.password) {
        this.$message.error('请输入密码');

        return;
      }
      if (!this.confirmPassword) {
        this.$message.error('请输入确认密码');
        return;
      }
      if (this.confirmPassword != this.password) {
        this.$message.error('两次密码不一致');
        return;
      }
      this.$myRequest
        .post('hsgov/resetPassword', {
          username: this.username,
          uuid: this.uuid,
          code: this.code,
          // code: '123456',
          password: this.password,
        })
        .then(res => {
          this.$message({
            message: '密码修改成功！',
            type: 'success'
          });
          this.$router.push("/login")
        });
    }
  }

}
</script>

<style lang="scss" scoped>
.reset {
  background: #f5f5f5;
  .btn {
    width: 900px;
    height: 56px;
    margin: 0 auto;
    margin-top: 69px;

    .operation-btn {
      width: 100%;
      height: 100%;
      line-height: 100%;
      border: 0;
      background: #0366e2;
      font-size: 18px;
      color: #ffffff;
      border-radius: 8px 8px 0 0;
    }
  }

  .inner-container {
    background: #ffffff;
    width: 900px;
    height: 420px;
    margin: 0 auto;
    padding: 73px 51px 66px 51px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-container {
      width: 467px;
      height: 38px;
      margin-bottom: 15px;

      input {
        width: 100%;
        height: 100%;
        padding-left: 17px;
        border-radius: 4px;

        border: 1px solid #d9dde1;
        // border: 0;
        // margin: 0;
        // padding: 0;
      }
    }

    .code-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .code {
        width: 120px;
        height: 38px;
        border: 1px solid #d9dde1;
        margin-left: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0366e2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .btn-wrapper {
      margin-top: 50px;
      .button {
        width: 90px;
        height: 35px;
        background: #0366e2;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-input,
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
  font-size: 12px;
  border: 0;
}
</style>