import axios from 'axios'
import router from "../router";
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import {
  getToken,
  removeToken
} from '@/utils/auth'
console.log(process.env.VUE_APP_BASE_API)
// create an axios instance
const service = axios.create({
  baseUrl: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 500000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // console.log('store')
    // console.log(store)
    // do something before request is sent

    if (config.url === "hsgov/login" || config.url === "hsgov/resetPassword" || config.url === "hsgov/captchaPhone") {
      return config
    }

    if (store.getters.getToken) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.code !== 'E0000') {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })


      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401) {
        removeToken()
        router.push({
          path: "/login"
        })
        return
        // })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
