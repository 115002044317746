import Vue from 'vue'
import Vuex from 'vuex'
import {
  getToken
} from '@/utils/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    screenWidth: Boolean,
    token: "",
    //用户数据
    userInfo: {},
  },
  mutations: {
    change(state, screenWidth) {
      state.screenWidth = screenWidth
    },
    setToken(state, data) {
      console.log("setToken")
      console.log(data)
      // console.log("setToken")
      // state.token = data.token
      state.token = data.token
      localStorage.token = data.token //同步存储token至localStorage
    },
    //储存用户信息
    setUserInfo(state, data) {
      console.log("setUserInfo")
      if (data) {
        // state.userInfo = Object.assign({}, state.userInfo, data);
        // window.sessionStorage.setItem('userInfo', JSON.stringify(state.userInfo));
        state.userInfo = Object.assign({}, state.userInfo, data);
        localStorage.setItem("userInfo", JSON.stringify(state.userInfo)); //返回{"a":1,"b":2}
        // localStorage.userInfo = data.token
        // uni.setStorageSync('userInfo', state.userInfo);
      }
    },
  },
  actions: {},
  getters: {
    getToken(state) {
      if (!state.token) {
        state.token = localStorage.getItem('token')
      }
      return state.token
    },

    getUserInfo(state) {
      if (!state.userInfo) {
        state.userInfo = JSON.parse( localStorage.getItem("userInfo"))
      }
      return state.userInfo
    },
    // token: state => getToken() ? getToken() : state.token,
    // userInfo: state => window.sessionStorage.getItem("userInfo") ? window.sessionStorage.getItem("userInfo") : state.userInfo,
    screenWidth: state => state.screenWidth
  },
  modules: {}
})
