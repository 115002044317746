<template>
  <div class="detail">
    <top />
    <banner />
    <div class="detail-title-container">
      <div class="detail-title">
        <span @click="$router.push('/')">首页&nbsp;>&nbsp;</span>
        政务信息报送 > 政务报送详情
      </div>
    </div>

    <bottom />
  </div>
</template>

<script>
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
import banner from "@/components/general/banner.vue";
export default {
  components: {
    top,
    bottom,
    banner
  },
}
</script>

<style lang="scss" scoped>
.detail {
  .detail-title-container {
    background: #f5f5f5;
  }
  .detail-title {
    height: 45px;
    line-height: 45px;
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    width: 900px;
    margin: 0 auto;

    span {
      color: #999999;
    }
  }
}
</style>