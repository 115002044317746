<template>
  <div class="evaluteList">
    <top />
    <div class="btn">
      <el-button class="operation-btn">评论列表</el-button>
    </div>

    <ul class="listData">
      <li v-for="(item,index) in listData"
          :key="index">
        <p>
          <span>{{item.content}}</span>
          <span>{{item.createTime}}</span>
        </p>
        <p>
          <span>{{item.nickName}}</span>
          <el-tooltip class="item"
                      effect="dark"
                      content="去评论"
                      placement="top-start">
            <img src="../assets/images/evalute.png"
                 alt=""
                 @click="evalute(item)">
          </el-tooltip>
          <!-- <span>{{item.createTime}}</span> -->
        </p>
      </li>

      <li v-if="!listData.length"
          style="text-align:center;font-size:16px">
        <p>
          <span>暂无数据</span>

          <el-tooltip class="item"
                      effect="dark"
                      content="去评论"
                      placement="top-start">
            <img src="../assets/images/evalute.png"
                 alt=""
                 @click="evalute"
                 style="width:20px;height:20px;cursor:pointer">
          </el-tooltip>
        </p>

      </li>
    </ul>

    <bottom />
    <el-dialog title="新增评论"
               :visible.sync="dialogShow"
               width="50%"
               destroy-on-close
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :modal-append-to-body="false"
               @open="openDialog">
      <textarea v-model="content"
                placeholder="请输入评论内容" />

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary"
                   @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
export default {
  components: {
    top,
    bottom,
  },
  data () {
    return {
      dialogShow: false,
      searchKey: "",
      listData: [],
      content: '',
      popupShow2: false,
      relationId: "",
      parentId: ""
    }
  },
  created () {
    this.relationId = this.$route.query.id
    this.getList()
  },
  methods: {
    async getList () {
      const res = await this.$myRequest.post("hsgov/gov/queryComment", {
        relationId: this.relationId,
        businessType: "RP"
      });
      this.listData = res.data
    },
    evalute (item) {
      this.dialogShow = true
      if (item) {
        this.parentId = item.id
      }

    },
    confirm () {
      this.dialogShow = false
      this.$myRequest
        .post("hsgov/gov/comment", {
          relationId: this.relationId,
          businessType: "RP",
          parentId: this.parentId,
          content: this.content
        }, {
          load: false
        }).then(res => {
          this.$message({
            message: '评论成功',
            type: 'success'
          });
          this.content = ""

          this.getList()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluteList {
  background: #f5f5f5;
  .btn {
    width: 900px;
    height: 56px;
    margin: 0 auto;

    .operation-btn {
      width: 100%;
      height: 100%;
      line-height: 100%;
      border: 0;
      background: #0366e2;
      font-size: 18px;
      color: #ffffff;
      border-radius: 8px 8px 0 0;
    }
  }

  .listData {
    background: #ffffff;
    width: 900px;
    height: 613px;
    overflow-y: auto;
    margin: 0 auto;
    padding: 24px 31px 66px 31px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 84px;

    li {
      list-style: none;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 20px;

      p:nth-of-type(1) {
        line-height: 22px;
        overflow: hidden;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span:nth-of-type(1) {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }

        span:nth-of-type(2) {
          font-size: 14px;
          font-weight: 400;
          color: #b1b1b1;
        }
      }

      p:nth-of-type(2) {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

textarea {
  width: 100%;
  height: 200px;
  padding: 20px;
  border: 1px solid #d9dde1;
}
</style>