import Vue from "vue";
import VueRouter from "vue-router";
import Err from "../views/404.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Detail from "../views/Detail.vue";
import List from "../views/List.vue";
import operationList from "../views/operationList.vue";
import userInfo from "../views/userInfo.vue";
import Contactus from "../views/Contactus.vue";
import complateUser from "../views/complateUser.vue";
import reset from "../views/reset.vue";
import forget from "../views/Forget.vue";
import evaluteList from "../views/evaluteList.vue";
import {
  getToken
} from '@/utils/auth'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [{
      path: "/login",
      name: "Login",
      // component: Login,
      meta: {
        title: "登录"
      },
      component: resolve => require(['../views/Login.vue'],resolve)

    }, {
      path: "/",
      name: "Home",
      meta: {
        title: "衡山政务信息管理平台"
      },
      component: resolve => require(['../views/Home.vue'],resolve)
    },
    {
      path: "/home",
      name: "Home",
      // component: Home,
      meta: {
        title: "衡山政务信息管理平台"
      },
      component: resolve => require(['../views/Home.vue'],resolve)
    },

    {
      path: "/reset",
      name: "reset",
      // component: reset,
      meta: {
        title: "重置"
      },
      component: resolve => require(['../views/reset.vue'],resolve)
    },
    {
      path: "/forget",
      name: "forget",
      // component: forget,
      meta: {
        title: "重置"
      },
      component: resolve => require(['../views/Forget.vue'],resolve)
    },
    {
      path: "/complateUser",
      name: "complateUser",
      // component: complateUser,
      meta: {
        title: "完善信息"
      },
      component: resolve => require(['../views/complateUser.vue'],resolve)
    },

    {
      path: "/Detail",
      name: "Detail",
      // component: Detail,
      meta: {
        title: "详情"
      },
      component: resolve => require(['../views/Detail.vue'],resolve)
    },
    {
      path: "/list",
      name: "List",
      // component: List,
      meta: {
        title: "列表"
      },
      component: resolve => require(['../views/List.vue'],resolve)
    },
    {
      path: "/operationList",
      name: "operationList",
      // component: operationList,
      meta: {
        title: "操作记录"
      },
      component: resolve => require(['../views/operationList.vue'],resolve)
    },

    {
      path: "/evaluteList",
      name: "evaluteList",
      // component: evaluteList,
      meta: {
        title: "评论列表"
      },
      component: resolve => require(['../views/evaluteList.vue'],resolve)
    },

    {
      path: "/contactus",
      name: "contactus",
      // component: Contactus,
      meta: {
        title: "通讯录"
      },
      component: resolve => require(['../views/Contactus.vue'],resolve)
    },
    {
      path: "/userInfo",
      name: "userInfo",
      // component: userInfo,
      meta: {
        title: "个人中心"
      },
      component: resolve => require(['../views/userInfo.vue'],resolve)
    },


    // {
    //   path: "/rofile",
    //   component: Rofile,
    //   meta: { title: "衡山政务信息管理平台" }
    // },
    // {
    //   path: "/contactus",
    //   component: Contactus,
    //   meta: { title: "衡山政务信息管理平台" }
    // },
    // {
    //   path: "/bsstory",
    //   component: Bsstory,
    //   meta: { title: "衡山政务信息管理平台" }
    // },
    // {
    //   path: "/babyknow",
    //   component: Babyknow,
    //   meta: { title: "衡山政务信息管理平台" }
    // },
    // {
    //   path: "/babycontent",
    //   component: Babycontent,
    //   meta: { title: "衡山政务信息管理平台" }
    // },
    {
      path: "*",
      // component: Err,
      component: resolve => require(['../views/404.vue'],resolve)
      //meta: {title: '小木博客'}
      //redirect: '/home'
    },
  ]
  // routes
});

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   next();
// });

router.beforeEach((to, from, next) => {
  // 如果有token 说明该用户已登陆
  if (getToken()) {
    // 在已登陆的情况下访问登陆页会重定向到首页
    if (to.path === '/login') {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    // 没有登陆则访问任何页面都重定向到登陆页
    if (to.path === '/login'||to.path === '/forget') {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach((to, from, next) => {

  window,
  scrollTo(0, 0)

})

export default router;
