<template>
  <div class="contactus">
    <top />
    <div class="banner">
      <img src="../assets/images/connectBg.png"
           alt="">
    </div>

    <div class="list-title-container">
      <div class="list-title">
        <span @click="$router.push('/')">首页&nbsp;>&nbsp;</span>
        <span>公共通讯录</span>
        <div class="search-box">
          <el-input v-model="searchKey"
                    placeholder="搜索联系人"></el-input>
          <img src="../assets/images/search-icon.png"
               @click="search"
               alt="">
        </div>
      </div>
    </div>

    <div class="content">
      <ul class="contactus-list">
        <li v-for="(item,index) in listData"
            :key="index"
            @click="change(index)"
            :class="currentIndex==index?'active':''">
          <p>
            <span class="name">{{item.userName}}</span>
            <span class="phone"
                  v-if="item.phonenumber">({{item.phonenumber}})</span>
          </p>
          <p>
            <span class="address">{{item.deptName}}</span>
          </p>
        </li>
        <p v-if="!listData.length"
           style="text-align:center;font-size:16px;margin-bottom:30px">暂无数据</p>
      </ul>
    </div>

    <bottom />
  </div>
</template>

<script>
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
export default {
  components: {
    top,
    bottom
  },
  data () {
    return {
      searchKey: "",
      currentIndex: "",
      listData: [],
      currentPage: 1,
      pageSize: 100
    }
  },

  created () {
    this.getList()
  },
  methods: {
    search () {
      this.getList()
    },
    async getList () {
      const res = await this.$myRequest.post("hsgov/getAddressBook", {
        userName: this.searchKey,
        currentPage: 1,
        pageSize: 100
      });
      let data = res.data.list
      const result = []
      for (let i in data) {
        data[i].forEach((item) => {
          result.push(item)
        })
      }
      this.listData = result
    },
    change (index) {
      this.currentIndex = index
    }
  }

}
</script>

<style lang="scss" scoped>
.contactus {
  .banner {
    height: 390px;
    img {
      height: 390px;
    }
  }

  .list-title-container {
    background: #f5f5f5;
  }
  .list-title {
    height: 45px;
    // line-height: 45px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;

    width: 900px;
    margin: 0 auto;
    position: relative;
    span {
      display: inline-block;
      line-height: 45px;
      cursor: pointer;
    }

    span {
      &:first-child {
        color: #999999;
      }

      &:last-child {
        color: #000000;
      }
    }
  }

  .search-box {
    position: absolute;
    right: 0;
    top: 8px;
    width: 225px;
    height: 30px;
    cursor: pointer;

    img {
      position: absolute;
      right: 14px;
      top: 8px;
      width: 14px;
      height: 14px;
    }
  }

  .content {
    background: #fff;

    .contactus-list {
      width: 900px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      padding-top: 32px;

      li {
        width: 282px;
        height: 115px;
        background: #f8f8f8;
        margin-right: 26px;
        margin-bottom: 26px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 38px;
        border-radius: 10px;
        cursor: pointer;

        span {
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          font-size: 18px;
        }

        .phone {
          font-size: 14px;
          margin-left: 10px;
        }

        .address {
          font-size: 14px;
          color: #7d8892;
        }
      }

      li:nth-of-type(3n) {
        margin-right: 0;
      }

      .active {
        border: 1px solid #b4c6dc;
      }
    }
  }
}

::v-deep .el-input,
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border-radius: 0;
  border: 0;
  top: 0;
}
</style>