<template>
  <div class="container">
    <top />
    <div class="btn">
      <el-button class="operation-btn">操作记录</el-button>
    </div>

    <ul class="listData">
      <li v-for="(item,index) in listData"
          :key="index">
        <p>
          <span>{{item.title}}</span>
          <span>{{item.createTime}}</span>
        </p>
        <p>{{item.operateMsg}}</p>
      </li>
      <li v-if="!listData.length"
          style="text-align:center;font-size:16px">暂无数据</li>
      <!-- 
      <li>
        <p>
          <span>张启山</span>
          <span>2021-08-12 08:26:31</span>
        </p>
        <p>我是操作内容，我做了那些操作等等一系列的操作记录。</p>
      </li>

      <li>
        <p>
          <span>张启山</span>
          <span>2021-08-12 08:26:31</span>
        </p>
        <p>我是操作内容，我做了那些操作等等一系列的操作记录。</p>
      </li> -->
    </ul>

    <bottom />
  </div>
</template>

<script>
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
export default {
  components: {
    top,
    bottom,
  },
  data () {
    return {
      searchKey: "",
      listData: [],
      currentPage: 1,
      pageSize: 100
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await this.$myRequest.post("hsgov/operate/queryOperateList", {
        currentPage: 1,
        pageSize: 100
      });
      this.listData = res.data.list


    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #f5f5f5;
  .btn {
    width: 900px;
    height: 56px;
    margin: 0 auto;

    .operation-btn {
      width: 100%;
      height: 100%;
      line-height: 100%;
      border: 0;
      background: #0366e2;
      font-size: 18px;
      color: #ffffff;
      border-radius: 8px 8px 0 0;
    }
  }

  .listData {
    background: #ffffff;
    width: 900px;
    height: 613px;
    overflow-y: auto;
    margin: 0 auto;
    padding: 24px 31px 66px 31px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 84px;

    li {
      list-style: none;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 20px;

      p:nth-of-type(1) {
        line-height: 22px;
        overflow: hidden;
        margin-bottom: 14px;
        span:nth-of-type(1) {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          float: left;
        }

        span:nth-of-type(2) {
          font-size: 14px;
          font-weight: 400;
          color: #b1b1b1;
          float: right;
        }
      }

      p:nth-of-type(2) {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>