<template>
  <div class="nav">
    <div class="main">
      <!-- <img src="@/assets/general/xttlogo.png" alt="" /> -->
      <div class="column">
        <div class="link">
          <router-link class="routerlink" to="/">首页</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/rofile"> 关于衡山政务信息管理平台</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/babyknow">育儿知识</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/bsstory">白霜故事</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/contactus">联系我们</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  background: #fff;
  height: 50px;
  display: flex;
  justify-content: center;
  z-index: 999;
  .main {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 70px;
    }
    .column {
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .link {
        transition: all 0.5s;
        .routerlink {
          font-size: 8px;
          margin: 0 10px;
        }
      }
      .link:hover {
        //color: gold;
        transform: translate(0, 3px);
        .routerlink {
          color: #34c5be;
          font-weight: 900;
        }

        // border-bottom: 1px solid gold;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .nav {
    width: 100%;
    height: 100px;
    background: #abde41;
    .main {
      width: 100%;
      height: 100%;
      display: flex;
      img {
        margin-left: 10px;
        width: 90px;
      }
      .column {
        padding-right: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .link {
          display: flex;
          align-items: center;
          .routerlink {
            font-size: 25px;
            font-weight: bold;
            color: #fff;
          }
        }
      }
    }
  }
  /*  .nav{
        position: fixed;
        top: 0;
    //   width: 100%;
    //   height: 5.333rem;
    //   box-sizing: border-box;
    //   //display: flex;
    //   //justify-content: flex-start;
      .main{
        width: 100vw;
        height: 150px;
        background: #ABDE41;
        // display: flex;
        // //justify-content: space-between;
        // align-items: center;
        img{
        width:20%;
        margin-left: 10px
      }
      .column{
          width:75% ;
          height:100%;
          display: flex;
          justify-content: flex-end;
          align-items:center;
          ul{width: 100%;
              font-size: 6px;
              color: #fff;
             font-weight:bold;
            //  display: flex;
            //  flex-wrap: wrap;
            //  justify-content: space-between;

              li{
                float: left;

              }
          }
       }
      }
   }*/
}
</style>
