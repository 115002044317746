<template>
  <div class="container">
    <top />
    <div class="btn">
      <el-button class="operation-btn">个人中心</el-button>
    </div>

    <div class="inner-container">
      <div class="userInfo">
        <div class="userDetail">
          <img src="../assets/images/boy.png"
               mode=""
               class="user-pic"
               v-if="userInfo.sex==1">
          <img src="../assets/images/gril.png"
               mode=""
               class="user-pic"
               v-else>
          <div>
            <p>{{userInfo.nickName}}</p>
            <p>{{userInfo.phonenumber}}</p>
          </div>
        </div>

        <div class="logoOut"
             @click="logoOut">
          退出登录
        </div>
      </div>

      <ul class="listData">
        <li @click="operate">
          <img src="../assets/images/czjl.png"
               alt="">
          <span>操作记录</span>
          <span>去查看</span>
        </li>

        <li @click="editPassword">
          <img src="../assets/images/xgmm.png"
               alt="">
          <span>修改密码</span>
          <span>去查看</span>
        </li>
      </ul>
    </div>

    <bottom />
  </div>
</template>

<script>
import {
  removeToken
} from '@/utils/auth'
import {
  mapState,
  mapMutations
} from 'vuex';
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
export default {
  components: {
    top,
    bottom,
  },
  computed: {
    ...mapState(['userInfo', 'loginPopupShow'])
  },
  data () {
    return {
      searchKey: ""
    }
  },
  methods: {
    logoOut () {
      removeToken()
      this.$router.push("/login")
    },
    operate () {
      this.$router.push({
        path: "/operationList"
      });
    },
    editPassword () {
      this.$router.push({
        path: "/reset"
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #f5f5f5;
  .btn {
    width: 900px;
    height: 56px;
    margin: 0 auto;
    margin-top: 69px;

    .operation-btn {
      width: 100%;
      height: 100%;
      line-height: 100%;
      border: 0;
      background: #0366e2;
      font-size: 18px;
      color: #ffffff;
      border-radius: 8px 8px 0 0;
    }
  }

  .inner-container {
    background: #ffffff;
    width: 900px;
    height: 420px;
    margin: 0 auto;
    padding: 73px 51px 66px 51px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 84px;
    box-sizing: border-box;

    .userInfo {
      overflow: hidden;
      .userDetail {
        float: left;

        width: 300px;

        img {
          width: 53px;
          height: 53px;
          border-radius: 100%;
          float: left;
          margin-right: 17px;
        }

        p:nth-of-type(1) {
          font-size: 14px;
          font-weight: 400;
          color: #010101;
          margin-bottom: 10px;
          padding-top: 2px;
        }

        p:nth-of-type(2) {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }
      .logoOut {
        float: right;
        width: 75px;
        height: 30px;
        border: 1px solid #0366e2;
        font-size: 14px;
        font-weight: 400;
        color: #0366e2;
        text-align: center;
        line-height: 30px;
        margin-top: 11px;
        cursor: pointer;
      }
    }

    .listData {
      overflow: hidden;
      padding-top: 84px;
      li {
        width: 370px;
        height: 104px;
        background: #f8f8f8;
        list-style: none;
        padding: 0 39px 0 28px;
        cursor: pointer;

        img {
          width: 35px;
          height: 35px;
          // margin-top: 35px;
          vertical-align: middle;
        }

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          display: inline-block;
          line-height: 104px;
          vertical-align: middle;
        }

        span:nth-of-type(1) {
          margin: 0 170px 0 14px;
        }

        span:nth-of-type(2) {
          font-size: 14px;
          color: #a7a7a7;
        }
      }
      li {
        float: left;
      }

      li:nth-of-type(2) {
        float: right;
      }
    }
  }
}
</style>