import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import store from "./store";
import service from "../src/utils/request";
import "amfe-flexible";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import formdata from "../src/utils/time"

//Vue.use(mavonEditor);
Vue.prototype.$myRequest = service;
Vue.prototype.$formData = formdata
Vue.config.productionTip = false;






new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
