<template>
  <div class="login">
    <div class="container">
      <div class="logo">
        <img src="../assets/images/logo.png"
             alt="">
        <h3>衡山政务信息管理平台</h3>
      </div>

      <div class="title-china">用 户 登 录</div>
      <!-- <div class="title-eng">User login</div> -->

      <div class="input-container">
        <el-input v-model="formData.username"
                  placeholder="请输入用户名"></el-input>
      </div>

      <div class="input-container">
        <el-input v-model="formData.password"
                  type="password"
                  placeholder="请输入密码"></el-input>
      </div>

      <div class="btn"
           @click="login">登录</div>

      <div class="forget">
        <span @click="forget">忘记密码？</span>
        <el-checkbox v-model="checked"
                     class="remeberPwd">记住密码</el-checkbox>
      </div>
    </div>
    <div class="footer">
      <div class="footer-container">
        <p style="font-size:16px">
          <span>主办：衡山县人民政府办公室</span>
          <span>承办：衡山县政府研究中心</span>
          <span>地址：湖南省衡山县开云镇解放南路99号</span>
        </p>
        <!-- <p>
          <span>网站备案号：备0534343245</span>
          <span>网站标识码：342343423430002</span>
          <span>建议使用1920*1080分辨率浏览器</span>
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  setToken
} from '@/utils/auth'
export default {
  data () {
    return {
      formData: {
        username: "",
        password: ""
      },
      token: "",
      // 记住密码checked
      checked: true
    }
  },
  mounted () {

    this.account(); //获取cookie的方法
  },

  methods: {
//     import Cookies from 'js-cookie'

// const TokenKey = 'loginToken'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }
    account () {
      // if (document.cookie.length <= 0) {
      // console.log(this.getCookie("username"));
      this.formData.username = this.getCookie("username");
      this.formData.password = this.getCookie("password");
      // }
    },
    setCookie (c_name, c_pwd, exdate) {
      //账号，密码 ，过期的天数
      var exdate = new Date();

      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdate); //保存的天数
      document.cookie =
        "username=" + c_name + ";path=/;expires=" + exdate.toLocaleString();
      document.cookie =
        "password=" + c_pwd + ";path=/;expires=" + exdate.toLocaleString();
    },
    getCookie (name) {
      var arr = document.cookie.split(";");
      //["_ga=GA1.1.1756734561.1561034020", " mobile=123" password=456"]
      for (var i = 0; i < arr.length; i++) {
        var arr2 = arr[i].split("="); // ["_ga", "GA1.1.1756734561.1561034020"]
        if (arr2[0].trim() == name) {
          return arr2[1];
        }
      }
    },
    clearCookie () {
      this.setCookie("", "", -1); //清除cookie
    },
    forget () {
      this.$router.push("/forget")
    },
    async login () {
      if (!this.formData.username) {
        this.$message.error('请输入用户名');
        return;
      }

      if (!this.formData.password) {
        this.$message.error('请输入密码');

        return;
      }

      if (this.checked == true) {
        //存入cookie
        this.setCookie(
          this.formData.username,
          this.formData.password,
          30
        ); //保存7天
      } else {
        this.clearCookie();
      }
      const res = await this.$myRequest.post("hsgov/login", {
        username: this.formData.username,
        password: this.formData.password,
      });
      setToken(res.data)
      this.$store.commit('setToken', {
        token: res.data
      });

      this.$message({
        message: '登录成功',
        type: 'success'
      });

      setTimeout(() => {
        this.$router.push("/")
      }, 500)
      // this.babyInfo = res.data.data;
    },
    // login(){
    //   console.log(this.$myRequest)
    // }
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100%;
  background: url("../assets/images/loginBg.png") no-repeat center center;
  background-position: center center;
  background-size: 100% 100%;
  position: relative;

  .container {
    width: 550px;
    height: 350px;
    opacity: 0.8;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
  }

  .logo {
    clear: both;
    padding: 15px 0 0 15px;
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    img {
      float: left;
      vertical-align: middle;
      // margin: 15px 9px 0 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 9px;
      object-fit: cover;
    }

    h3 {
      float: left;
      font-size: 18px;
      font-family: HYZongYiJ;
      font-weight: 400;
      color: #0366e2;
      line-height: 20px;
    }
  }

  .title-china {
    text-align: center;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .title-eng {
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #dce0e5;
    margin-bottom: 36px;
  }

  .input-container {
    width: 300px;
    height: 32px;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .btn {
    width: 100px;
    height: 30px;
    border-radius: 30px;
    background: linear-gradient(90deg, #0366e2, #06adf3);
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin: 48px auto 20px;
    cursor: pointer;
  }

  .forget {
    margin: 0 auto;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #818b90;
    line-height: 30px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    padding: 0 10px;

    span {
      display: inline-block;
      font-size: 20px;
      -webkit-transform: scale(0.8);
    }
  }

  .footer {
    width: 100%;
    height: 65px;
    opacity: 0.78;
    background: #212636;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 20px;

    .footer-container {
      margin: 0 auto;

      p {
        text-align: center;
        font-size: 7px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9aa2b3;

        &:first-child {
          margin-bottom: 10px;
        }

        span {
          display: inline-block;
          margin-right: 40px;
        }
      }
    }
  }
}
::v-deep .el-input,
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
  font-size: 16px;
}
</style>