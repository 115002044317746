<template>
  <div class="home">
    <top />
    <div class="banner">
      <img src="../assets/images/banner.png"
           alt="">

      <div class="search">
        <el-input v-model="searchKey"
                  placeholder="请输入搜索内容"></el-input>

        <div class="search-btn"
             @click="search">搜索</div>
      </div>
    </div>

    <div class="notice-container">
      <ul class="notice-list">
        <li @click="tabNavigate('zwbs')">
          <img src="../assets/images/zwbs.png"
               alt="">
          <div>
            <!-- <h4>政务参阅</h4>
            <p>降低行政运营成本，提升行政运转效率。</p> -->

            <!-- <h4>政务报送</h4>
            <p>降低行政运营成本，提升行政运转效率。</p> -->
            <h4>信息报送</h4>
          </div>
        </li>

        <li @click="tabNavigate('zwcy')">
          <img src="../assets/images/scj.png"
               alt="">
          <div>
            <!-- <h4>收藏夹</h4>
            <p>降低行政运营成本，提升行政运转效率。</p> -->
            <!-- <h4>信息通知</h4>
            <p>降低行政运营成本，提升行政运转效率。</p> -->
            <h4>政务参阅</h4>
          </div>
        </li>

        <li @click="tabNavigate('xxtz')">
          <img src="../assets/images/xxgx.png"
               alt="">
          <div>
            <!-- <h4>信息共享</h4>
            <p>降低行政运营成本，提升行政运转效率。</p> -->
            <!-- <h4>其他文件</h4>
            <p>降低行政运营成本，提升行政运转效率。</p> -->

            <h4>信息通知</h4>
          </div>
        </li>

      </ul>

      <!-- <div class="notice-cont">
        <img src="../assets/images/notice-icon.png"
             alt=""
             class="notice-icon">
        <span>
          欢迎您使用衡山政务通，本软件将高效聚合衡,欢迎您使用衡山政务通，本软件将高效聚合衡,欢迎您使用衡山政务通……
        </span>
      </div> -->
    </div>

    <div class="content">
      <!-- <div class="title">衡山政务信息业务平台</div> -->
      <!-- <div class="sub-title">全面覆盖衡山政务管理多个场景，实现降本增效，高度定制化管理模块</div> -->
      <ul class="icon-list">
        <li @click="tabNavigate('qtwj')">
          <div class="img-wrapper">
            <img src="../assets/images/qtwj.png"
                 alt="">
          </div>
          <p>其他文件</p>
        </li>
        <li @click="tabNavigate('xxgx')">
          <div class="img-wrapper">
            <img src="../assets/images/xxtz.png"
                 alt="">
          </div>
          <p>信息共享</p>
        </li>
        <li @click="tabNavigate('scj')">
          <div class="img-wrapper">
            <img src="../assets/images/zwcy.png"
                 alt="">
          </div>
          <p>收藏夹</p>
        </li>

        <li @click="goToConnect">
          <div class="img-wrapper">
            <img src="../assets/images/txl.png"
                 alt="">
          </div>
          <p>通讯录</p>
        </li>
        <!-- <li @click="tabNavigate('scj')">
          <div class="img-wrapper">
            <img src="../assets/images/xxtz.png"
                 alt="">
          </div>
          <p>收藏夹</p>
        </li> -->
      </ul>
      <!-- <ul class="news-list">
        <li>
          <h4 class="news-title">本周末衡山体育中心演出</h4>
          <p class="news-content">衡山体育中心演出，本着推广全名娱乐精神，本市于本周进行大型娱乐节目演出，届时，希望广大市民朋友前往观赏文娱汇演，还请市民朋友注意安全……</p>
          <img src="../assets/images/test1.png"
               alt="">
        </li>

        <li>
          <h4 class="news-title">本周末衡山体育中心演出</h4>
          <p class="news-content">衡山体育中心演出，本着推广全名娱乐精神，本市于本周进行大型娱乐节目演出，届时，希望广大市民朋友前往观赏文娱汇演，还请市民朋友注意安全……</p>
          <img src="../assets/images/test2.png"
               alt="">
        </li>
      </ul> -->
    </div>

    <!-- <div class="contact">
      <p>公共通讯录，即刻开启便民之路</p>
      <div>一键查询</div>
    </div> -->
    <bottom />
  </div>
</template>

<script>
import top from "@/components/general/top.vue";
import bottom from "@/components/general/bottom.vue";
import {
  mapState,
  mapMutations
} from 'vuex';
export default {
  components: {
    top,
    bottom
  },
  data () {
    return {
      searchKey: "",
    };
  },


  computed: {
    ...mapState(['userInfo'])
  },

  methods: {
    search () {
      this.$router.push({
        path: "/list",
        query: {
          suffixUrl: "search",
          params: this.searchKey
        }
      })
      // this.$myRequest
      //   .post("hsgov/gov/gov/queryGlobalList", {
      //     title: this.searchKey,
      //   }).then(res => {
      //     console.log(res.data.list)
      //     // if (res.data) {
      //     //   this.$notify.success({
      //     //     title: '成功',
      //     //     message: '收藏成功'
      //     //   });
      //     //   this.loadData()
      //     // } else {
      //     //   this.$notify.error({
      //     //     title: '失败',
      //     //     message: '收藏失败'
      //     //   });
      //     // }
      //   })
    },
    goToConnect () {
      this.$router.push({
        path: "/contactus"
      })
    },
    tabNavigate (suffixUrl) {
      this.$router.push({
        path: "/list",
        query: {
          suffixUrl
        }
      })
    },


  }
};
</script>
<style lang="scss" scoped>
.home {
  .banner {
    height: 525px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    .search {
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 900px;
      height: 45px;

      .search-btn {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        width: 75px;
        height: 38px;
        background: #0366e2;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
      }
    }
  }

  .contact {
    background: url("../assets/images/contact.png") no-repeat center center;
    background-position: center center;
    background-size: 100% 100%;
    height: 150px;

    p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      padding: 30px 0;
    }

    > div {
      width: 128px;
      height: 38px;
      border-radius: 38px;
      background: #ffffff;
      text-align: center;
      line-height: 38px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0366e2;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .notice-container {
    height: 140px;
    background: #ffffff;
    .notice-list {
      height: 140px;
      width: 900px;
      margin: 0 auto;
      text-align: center;
      clear: both;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        list-style: none;
        height: 76px;
        text-align: left;
        cursor: pointer;

        img {
          width: 68px;
          height: 76px;
          float: left;
          margin-right: 26px;
        }

        > div {
          float: left;
          h4 {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            padding: 22px 0;
            // margin: 7px 0 14px 0;
          }

          p {
            width: 116px;
            height: 29px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a7a7a7;
          }
        }
      }
    }

    .notice-cont {
      width: 900px;
      height: 38px;
      margin: 0 auto;
      background: #f5faff;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #787c82;

      span {
        display: inline-block;
        line-height: 38px;
      }

      .notice-icon {
        width: 15px;
        height: 14px;
        margin: 12px 11px;
        float: left;
      }
    }
  }

  .content {
    height: 418px;
    background: #f5faff;

    .title {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding: 59px 0 0 0;
    }

    .sub-title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #838b94;
      text-align: center;
    }

    .icon-list {
      width: 900px;
      margin: 0 auto;
      padding: 70px 0 80px 0;
      text-align: center;
      // padding-left: 75px;
      height: 246px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        .img-wrapper {
          width: 45px;
          height: 45px;
          background: #9bb2cc;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          margin-bottom: 18px;

          img:nth-of-type(1) {
            width: 25px;
            height: 23px;
          }

          img:nth-of-type(2) {
            width: 20px;
            height: 17px;
          }

          img:nth-of-type(3) {
            width: 24px;
            height: 22px;
          }

          img:nth-of-type(4) {
            width: 21px;
            height: 23px;
          }
        }

        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #55606a;
        }
      }
    }

    .news-list {
      margin: 0 auto;
      width: 900px;
      height: 384px;
      background: #ffffff;
      padding: 0 68px;

      li {
        width: 50%;
        list-style: none;
        text-align: left;
        float: left;

        .news-title {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          padding: 50px 0 20px 0;
        }

        .news-content {
          width: 336px;
          height: 47px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #979798;
          margin-bottom: 30px;
          line-height: 18px;
        }

        img {
          width: 333px;
          height: 147px;
        }
      }
    }
  }
}

::v-deep .el-input,
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 0;
  border: 0;
  // opacity: 0.72;
}
</style>
