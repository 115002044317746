<template>
  <div class="bottom">
    <div class="bottom-title">衡山政务信息管理系统</div>
    <div class="footer-container">
      <p style="font-size:16px">
        <span>主办：衡山县人民政府办公室</span>
        <span>承办：衡山县政府研究中心</span>
        <span>地址：湖南省衡山县开云镇解放南路99号</span>
      </p>
      <!-- <p>
        <span>网站备案号：备0534343245</span>
        <span>网站标识码：342343423430002</span>
        <span>建议使用1920*1080分辨率浏览器</span>
      </p> -->
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.bottom {
  height: 194px;
  background: #212636;

  .bottom-title {
    padding: 47px 0 34px 0;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }

  .footer-container {
    margin: 0 auto;

    p {
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9aa2b3;

      &:first-child {
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        margin-right: 40px;
      }
    }
  }
}
</style>